import React from 'react'

import {  
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    
  } from '@chakra-ui/react';


export function ButtonSanfona({title, children}) {
    return (
      <Accordion  allowToggle>
      <AccordionItem>
        
          <AccordionButton  _expanded={{ bg: 'yellow', color: 'black' }}>
            <Box as="span" flex='1' textAlign='center' my='2'>
              {title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
      
        <AccordionPanel pb={4}>
         {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>  
    );
}

export default ButtonSanfona