import React from 'react'
import axios from 'axios';
import { API_URL } from '../../api';


import {
  Box,
} from '@chakra-ui/react';


import { FaRegKissBeam, FaRegFlushed } from 'react-icons/fa';
import logo from '../../Assets/Png/logo.png'


function IconNotification({mensagem}) {
  const [ultimoAtend, setultimoAtend] = React.useState(1000000)

  const date = new Date();
  const year = date.getFullYear()
  const month = date.getMonth()+1
  const day = date.getDate()

  React.useEffect(() => {
    localStorage.setItem("@Sia_ultimoAtend", 1000000);

    const func = setInterval(() => {
      buscaAtendimento()
    }, 60000);
    return () => clearInterval(func);
  },[])

  function buscaAtendimento(){
    axios({
      method: 'get',
      url: `${API_URL}/atend/${year}-${month}-${day}/${year}-${month}-${day}`,
    })
      .then(function (response) {
        // let ultimoVazio = 0
        // let ultimo = 0 

        const ultimoVazio = response.data.Data.filter(item => (item.status_atendimento === 0 && item.cod_usu_fin === 0))[0]?.id_atend
        const ultimo = response.data.Data.filter(item => (item.status_atendimento === 0))[0]?.id_atend
          
            // console.log(ultimoVazio, ultimo)
        if((ultimoVazio === ultimo) && (ultimoVazio) && (ultimo)){
            console.log(ultimoVazio, ultimo)
            notifyMe()
        }
      });
  }
  


  function notifyMe() {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("seu navegador nao suporta notificações");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      const notification = new Notification("SIA - Novo Atendimento", {
        body: mensagem,
        icon: logo,
      });
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification(mensagem);
          // …
        }
      });
    }
  
    // https://www.delftstack.com/pt/howto/javascript/play-audio-javascript/
  }

  
  return (
    <Box direction='column'>
      
    </Box>
  );
}

export default IconNotification