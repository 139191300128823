import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link as ReactLink } from 'react-scroll';
import { Link as LinkNav } from 'react-router-dom';
import IconNotification from './../IconNotification/index';

import {
  Icon,
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  Stack,
  Heading,
  useToast,
  Image,
  Text,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

import { ColorModeSwitcher } from './../../styles/ColorModeSwitcher';
import PerfilPhoto from '../PerfilPhoto';
import ButtonPopver from '../ButtonPopver';
import LinkButton from '../LinkButton';
import ItemMenu from '../ItemMenu';
import ButtonSanfona from '../ButtonSanfona';

import logo from '../../Assets/Png/logo.png';

import { UserContext } from '../../Context/UserContext';
import { API_URL } from './../../api';

import {
  FaUserFriends,
  FaBusinessTime,
  FaCalendarTimes,
  FaCalendarPlus,
  FaShippingFast,
} from 'react-icons/fa';
import { GoFileSubmodule } from 'react-icons/go';

export function Header() {
  const { login, IsLogin, isOpen, onOpen, onClose, dados } =
    React.useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [userLogado, setuserLogado] = React.useState(
    JSON.parse(window.localStorage.getItem('UserData'))
  );

  const toast = useToast();

  const NavLink = ({ children }) => (
    <ReactLink //tive que mudar para o link do react
      to={children.link}
      smooth
      offset={-100}
      duration={600}
      delay={100}
    >
      <Flex align="center" _hover={{ color: 'yellow' }} gap="1">
        <Icon as={children.icon} />
        <Text
          cursor="pointer"
          fontFamily="Ubuntu Mono"
          textTransform="uppercase"
          fontSize={{ md: '11', lg: '14' }}
        >
          {children.nome}
        </Text>
      </Flex>
    </ReactLink>
  );

  return (
    <>
      {!IsLogin && (
        <Box
          px={4}
          bg={API_URL === 'https://sia.gruposi.com.br' ? '#0b090a' : 'green'}
          color="white"
          position="fixed"
          w="100vw"
          top="0"
          left="0"
          zIndex="999"
        >
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'md'}
              bg={'#0b090a'}
              color="white"
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />

            <HStack spacing={10} alignItems={'center'}>
              <LinkNav to={'/'}>
                <Image src={logo} h="35" />
              </LinkNav>
              <HStack
                as={'nav'}
                spacing={2}
                display={{ base: 'none', md: 'flex' }}
              >
                <LinkButton
                  title={'DASHBOARD'}
                  link="/"
                  colorB={location.pathname === '/' ? 'yellow' : 'white'}
                ></LinkButton>
                <ButtonPopver
                  title="SUPORTE"
                  colorB={
                    location.pathname === '/Atendimento' ||
                    location.pathname === '/Agendamento'
                      ? 'yellow'
                      : 'white'
                  }
                >
                  <ItemMenu
                    title="Atendimento"
                    link="/Atendimento"
                    icon={FaUserFriends}
                  />
                  <ItemMenu
                    title="Agendamento"
                    link="/Agendamento"
                    icon={FaCalendarTimes}
                  />
                  <ItemMenu
                    title="Arquivos"
                    link="/Arquivos"
                    icon={GoFileSubmodule}
                  />
                </ButtonPopver>
                <LinkButton
                  title={'DESENVOLVIMENTO'}
                  link="/Desenvolvimento"
                  colorB={
                    location.pathname === '/Desenvolvimento'
                      ? 'yellow'
                      : 'white'
                  }
                ></LinkButton>
                {userLogado && userLogado.cod_grupo === 1 && (
                  <ButtonPopver
                    title="ADMINISTRAÇÃO"
                    colorB={
                      location.pathname === '/BancodeHoras' ||
                      location.pathname === '/GestaodeFolgas' ||
                      location.pathname === '/MonitordeClientes' ||
                      location.pathname === '/Licencas'
                        ? 'yellow'
                        : 'white'
                    }
                  >
                    <ItemMenu
                      title="Banco de Horas"
                      link="/BancodeHoras"
                      icon={FaBusinessTime}
                    />
                    <ItemMenu
                      title="Horas Extras"
                      link="/HorasExtras"
                      icon={FaCalendarPlus}
                    />
                    <ItemMenu
                      title="Monitor de Clientes"
                      link="/MonitordeClientes"
                      icon={FaUserFriends}
                    />
                    {userLogado && userLogado && userLogado.cod_grupo === 1 && (
                      <ItemMenu
                        title="Cadastrar Empresa Delivery"
                        link="/CadastraEmpDel"
                        icon={FaShippingFast}
                      />
                    )}
                    {/* {userLogado && userLogado && userLogado.cod_grupo === 1 && <ItemMenu title='Gerenciador de Usuários' link='/GerenciaUsuario'/>} */}
                  </ButtonPopver>
                )}
              </HStack>
            </HStack>
            <Flex alignItems={'center'}>
              {login ? (
                <PerfilPhoto />
              ) : (
                <Button
                  mr="2"
                  size={{ base: 'xs', md: 'sm' }}
                  colorScheme="yellow"
                  variant="outline"
                  onClick={() => navigate('/login')}
                >
                  Login
                </Button>
              )}
              <ColorModeSwitcher />

              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}
                ></MenuButton>
              </Menu>
            </Flex>
          </Flex>

          {isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={4}>
                <LinkButton title={'Dashboard'} link="/"></LinkButton>
                <ButtonSanfona title="Suporte">
                  <ItemMenu
                    title="Atendimento"
                    link="/Atendimento"
                    icon={FaUserFriends}
                  />
                  <ItemMenu
                    title="Agendamento"
                    link="/Agendamento"
                    icon={FaCalendarTimes}
                  />
                  <ItemMenu
                    title="Arquivos"
                    link="/Arquivos"
                    icon={GoFileSubmodule}
                  />
                </ButtonSanfona>
                <LinkButton
                  title={'Desenvolvimento'}
                  link="/Desenvolvimento"
                ></LinkButton>
                {userLogado && userLogado.cod_grupo === 1 && (
                  <ButtonSanfona title="Administração">
                    <ItemMenu
                      title="Banco de Horas"
                      link="/BancodeHoras"
                      icon={FaBusinessTime}
                    />
                    <ItemMenu
                      title="Horas Extras"
                      link="/HorasExtras"
                      icon={FaCalendarPlus}
                    />
                    <ItemMenu
                      title="Monitor de Clientes"
                      link="/MonitordeClientes"
                      icon={FaUserFriends}
                    />
                    {userLogado && userLogado && userLogado.cod_grupo === 1 && (
                      <ItemMenu
                        title="Cadastrar Empresa Del."
                        link="/CadastraEmpDel"
                        icon={FaShippingFast}
                      />
                    )}
                  </ButtonSanfona>
                )}
              </Stack>
            </Box>
          ) : null}
          <IconNotification
            mensagem={
              'Clique no botão de atualizar para ver o novo atendimento'
            }
          />
        </Box>
      )}
    </>
  );
}

export default Header;
