import React from 'react'
import { Link } from "react-router-dom";

import {    Button, Text, Center  } from '@chakra-ui/react';

import { UserContext } from '../../Context/UserContext';


export function LinkButton({title, link, colorB}) {
    const { isOpen, onOpen, onClose } = React.useContext(UserContext);

    return (
        <Link to={link} onClick={onClose}>
            <Center><Button colorScheme='black' variant='ghost' >
                <Text color={colorB} _hover={{ color: "yellow"}}>{title}</Text>
            </Button ></Center> 
        </Link>
    );
}

export default LinkButton