// theme.ts (Version 2 needs to be a tsx file, due to usage of StyleFunctions)
import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

// Version 1: Using objects
const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        fontFamily: 'body',
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: mode('#f8f9fa', '#0b090a')(props),
        lineHeight: 'base',
      },
    }),
  },
  components: {
    Checkbox: { // can be Radio
      baseStyle: {
        label: {
          touchAction: 'none',
          pointerEvents: "none",
        },
        container: {
          touchAction: 'none',
        },
      },
    },
  },
  breakpoints: {
        sm: '320px',
        xsm:'500px',
        md: '800px',
        lg: '960px',
        xl: '1200px',
      }
})

 export default theme;


// import { extendTheme } from '@chakra-ui/react'

// const customTheme = {
//   components: {
//     Text: {
//       variants: {
//         subtitle: {
//           color: 'gray.500',
//           fontWeight: '14px',
//         },
//       }
//     }
//   },
//   // styles: {
//   //   global: (props) => ({
//   //     'html, body': {
//   //       fontSize: 'sm',
//   //       backgroundColor: props.colorMode === 'dark' ? 'white' : 'gray.600',
//   //       lineHeight: 'tall',
//   //     },
//   //     a: {
//   //       color: props.colorMode === 'dark' ? 'teal.300' : 'teal.500',
//   //     },
//   //   }),
//   // },


// };

// const breakpoints = {
//     sm: '320px',
//     xsm:'500px',
//     md: '800px',
//     lg: '960px',
//     xl: '1200px',
//   }

// const theme = extendTheme(customTheme, {breakpoints});

// export default theme;