import React from 'react';
import { useNavigate } from "react-router-dom";
import { API_URL} from '../api';

import { useDisclosure, useToast } from '@chakra-ui/react';
import axios from 'axios';

export const UserContext = React.createContext();

export const UserDataContext = ({ children }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure(); //para fechar o menu
  const toast = useToast()

  const [login, setLogin] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [dados, setDados] = React.useState(null);
  const [TokenSI, setTokenSI] = React.useState('');
  const [superUsu, setSuperUsu] = React.useState(false);
  const [IsLogin, setIsLogin] = React.useState(true); // para esconder o header no login


  React.useEffect(() => {
    fetchData()
    ValidaNivelUser()
  }, [])



  // função que busca os dados ao atualizar a pagina !importante!
  const fetchData = async () => {
    if (window.localStorage.getItem('tokenSi') !== null) {
      const tokenSi = window.localStorage.getItem('tokenSi')
      if (VerificaToken(tokenSi)) {
        setLogin(true);
      } else {
        toast(toastErroDados)
        navigate('/login')
      }
    } else {
      setLogin(false)
    }
  }

  // função que valida o nivel de acesso ao atualizar a pagina 
  function ValidaNivelUser() {
    const consut = window.localStorage.getItem('UserData')
    const dadosUsuario = (JSON.parse(consut))
    if (dadosUsuario !== null) {
      if (dadosUsuario.cod_grupo === 1) {
        setSuperUsu(true)
      }
      else {
        setSuperUsu(false)
      }
    }
  }

  async function VerificaToken(token) {

    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }
    await axios.get(`${API_URL}/verifytoken`, config).then(value => {
      if (!value.statusText === 'ok') {
        navigate('/login')
        throw new Error(`Token invalido`)
      }
    }).catch(value => {
      navigate('/login')
      throw new Error(`Error`)
    })
  }

  async function GetUser(token, id) {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }
    // const { url, options } = USER_GET(token, id)
    const dataRes = await axios.get(`${API_URL}/user/id/${id}`, config)
    if (!dataRes.statusText === 'ok') throw new Error(`Error: `)
    const { Data } = await dataRes.data
    return Data;
  }

  async function Userlogin(username, password) {
    setError(false)
    setLoading(true);

    if ((username === '') || (password === '')) {
      setError(true)
      toast(toastErroDados)
      setLoading(false)
      setLogin(false)
      return
    }


    const postLogin = async () => {
      axios
        .post(`${API_URL}/user/login/`, {
          nome_usu: username,
          senha: password,
        })
        .then(async function (response) {
          setLoading(false)

          const dadosUsuario = await GetUser(response?.data.token, response?.data.cod_usu)
          if (dadosUsuario !== null) {
            if (dadosUsuario.ativo === 'S') {
              window.localStorage.setItem('tokenSi', response?.data.token)
              window.localStorage.setItem('UserData', JSON.stringify(dadosUsuario))

              ValidaNivelUser()

              toast(toastSucessoLogin)
              setError(false)
              setDados(dadosUsuario)
              setTokenSI(response?.data.token)
              setLogin(true)
              setLoading(false);
              navigate('/')
            } else {
              setError('Cliente inativo')
              toast(toastClienteInativo)
              setLogin(false)
              setLoading(false);
            }
          } else {
            setLoading(false);
            setError('Cliente Inexistente')
            setLogin(false)
          }
        })
        .catch(function (error) {
          setLoading(false);
          toast(dadosInvalidos)
          console.log(error);
        });
    }
    postLogin()



  }


  function Userlogout() {
    setLogin(false)
    setIsLogin(true);
    window.localStorage.removeItem('tokenSi')
    window.localStorage.removeItem('UserData')
    toast(toastLogout)
    navigate('/login')
  }


  function formataData(data) {
    const dividido = data.split('-')
    return (`${dividido[2]} - ${dividido[1]} - ${dividido[0]}`)
  }


  return (
    <UserContext.Provider
      value={{
        login, setLogin, loading, error, Userlogout, dados, setDados, superUsu,
        Userlogin, Userlogout, setIsLogin, IsLogin, isOpen, onOpen, onClose, formataData
      }}
    >
      {children}
    </UserContext.Provider>
  );
}


const toastErroLogin = {
  title: 'Falha no login.',
  description: "Tente novamente mais tarde.",
  status: 'error',
  duration: 4000,
  isClosable: true,
}

const toastErroDados = {
  title: 'Dados Invalidos.',
  description: "è necessario preencher todos os campos.",
  status: 'error',
  duration: 4000,
  isClosable: true,
}
const dadosInvalidos = {
  title: 'Email ou senha incorretos!',
  description: "tente novamente ou entre em contato com o ADM do sistema",
  status: 'warning',
  duration: 4500,
  isClosable: true,
}

const toastClienteInativo = {
  title: 'Cliente Inativo.',
  description: "Entre em contato com os administradores do sistema.",
  status: 'error',
  duration: 4000,
  isClosable: true,
}

const toastLogout = {
  title: 'Logout Realizado.',
  description: "Ate a proxima!",
  status: 'info',
  duration: 3000,
  isClosable: true,
}

const toastSucessoLogin = {
  title: 'Login Realizado.',
  description: "Tudo certo, vamos ao trabalho!",
  status: 'success',
  duration: 3000,
  isClosable: true,
}