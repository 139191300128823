import React from 'react'
import { Link } from "react-router-dom";

import { UserContext } from '../../Context/UserContext';

import { Flex, Text,  Icon  } from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';


export function ItemMenu({link, title, icon}) {


    const { isOpen, onOpen, onClose } = React.useContext(UserContext);
    return (
    
    <Link to={link} >
        <Flex color={'white'} align='center' gap='2' m='3' _hover={{ color: "yellow"}} onClick={onClose}>
            <Icon as={icon} w='5' my='2'/>
            <Text >{title}</Text>
        </Flex>
    </Link>
    );
}

export default ItemMenu