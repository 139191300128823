import React from 'react'
import axios from 'axios'
//https://morioh.com/p/0f44661af5c3

import { UserContext } from '../../Context/UserContext';
import './styles.css'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Text,
  PopoverArrow,
  Box,
  Flex,
  Divider,
  useColorModeValue,
  Image,
  Center,
  Icon,
  IconButton,
  useDisclosure,
  Collapse,
  Input,
  Spinner,
} from '@chakra-ui/react';

import { FaSync } from "react-icons/fa";
import { BsImage } from "react-icons/bs";
import { API_URL } from '../../api';



export function PerfilPhoto() {
  const { Userlogout, dados, setDados } = React.useContext(UserContext);

  const [UserData, setUserData] = React.useState(JSON.parse(window.localStorage.getItem('UserData')))
  const [image, setImage] = React.useState(null);
  const [imagesrc, setImagesrc] = React.useState();
  const [loading, setloading] = React.useState(false);
  const { isOpen, onToggle } = useDisclosure()

  React.useEffect(() => {
    if (window.localStorage.getItem('UserData') !== null) {
      setDados(JSON.parse(window.localStorage.getItem('UserData'))) // para carregar os dados do usuario
    }

  }, [])

  React.useEffect(() => {
    axios.get(`${API_URL}/user/id/${UserData.cod_usu}`).then((response) => {
      setImagesrc(`${API_URL}/imgperfil/${response.data.Data.nome_img_perfil}`)
    })
  }, [setImagesrc])


  const uploadImage = async e => {
    e.preventDefault();
    setloading(true)
    const formData = new FormData();
    formData.append('imgperfil', image);

    const headers = {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }

    await axios.post(`${API_URL}/user/${UserData.cod_usu}/imgperfil`, formData, headers)
      .then((response) => {
        axios.get(`${API_URL}/user/id/${UserData.cod_usu}`).then((response) => {
          setImagesrc(`${API_URL}/imgperfil/${response.data.Data.nome_img_perfil}`)
          setloading(false)
          setImage(null)
        })

      }).catch((err) => {
        setloading(false)
      });
    setloading(false)
  }


  return (
    <Popover >
      <PopoverTrigger>
        <Button size='sd' height='30px' width='32px' colorScheme='yellow' borderRadius='full' fontSize={'15'}>
          {UserData.nome_img_perfil ?
            (<Image src={imagesrc} objectFit='cover' w='100%' h='100%' borderRadius='full'></Image>)
            :
            (dados && dados.nome_usu.substr(0, 1))}
        </Button>

      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Box >
            <Center>
              <Box p='5' w={{ base: '150px', lg: '200px' }} h={{ base: '150px', lg: '200px' }}>
                <Image src={imagesrc} objectFit='cover' w='100%' h='100%' borderRadius='full'  boxShadow='dark-lg'></Image>
              </Box>
            </Center>
            <Flex direction='column' align='center'  boxShadow='xl' borderRadius='6' p='2'>
              <Flex gap='2'>
                <Text as='b' color={useColorModeValue('black', 'white')}>NOME:</Text>
                <Text color={useColorModeValue('black', 'white')}>{UserData && UserData.nome_usu}</Text>
              </Flex>
              <Flex gap='2'>
              <Text as='b' color={useColorModeValue('black', 'white')}>GRUPO:</Text>
              <Text color={useColorModeValue('black', 'white')}>{UserData ? (UserData.cod_grupo === 1 ? 'ADMINISTRADOR' :
                UserData.cod_grupo === 2 ? 'REVENDA' : UserData.cod_grupo === 3 ? 'SUPORTE' :
                  UserData.cod_grupo === 4 ? 'DESENVOLVIMENTO' : UserData.cod_grupo === 5 ? 'FINANCEIRO' :
                    UserData.cod_grupo === 6 ? 'SUPORTE' : 'COMERCIAL') : ''}
              </Text>
              </Flex>
            </Flex>
          </Box>
          <Divider m='2' mt='6' />
          <Flex justify='Space-between'>

            <Button colorScheme='twitter' variant='outline' size='sm' onClick={onToggle}>
            <Flex gap='2'>
              <FaSync />
              <BsImage />
            </Flex>
            </Button>

            <Button colorScheme='red' size='sm' onClick={Userlogout}>
              Deslogar
            </Button>

          </Flex>
          <Collapse in={isOpen} animateOpacity>
            <Box p='5px' mt='4' color='black' >
              {!loading ? (<Flex justify='space-between'>
                
                <form onSubmit={uploadImage}>
                  <Flex>
                    <Text as='b' mb='2' fontSize='14'>Clique para selecionar imagem: </Text>  
                  </Flex>
                  <input type="file" name="imgperfil" onChange={e => setImage(e.target.files[0])} />
                  <Divider my='2' mt='4' />
                  <Button isDisabled={!image}  colorScheme='green' size='sm' type="submit">Alterar</Button>
                </form>
              </Flex>) : (
                <Flex justify='center'>
                  <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='yellow.500'
                    size='xl'
                  />
                </Flex>
              )}
            </Box>
          </Collapse>
        </PopoverBody>
      </PopoverContent>
    </Popover>

  )

}

export default PerfilPhoto