export const API_URL_PROD = 'https://sia.gruposi.com.br';
export const API_URL_HOMO = 'http://gruposi.com.br:3005';
export const API_URL_LOCAL = 'http://localhost:3005/';

export const API_URL = API_URL_PROD;
// export const API_URL = API_URL_HOMO;
// export const API_URL = API_URL_LOCAL;

// as funções abaixo foram usadas apenas no login

export function TOKEN_POST(body) {
  return {
    url: API_URL + '/user/login',
    options: {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
} // ajustado

export function TOKEN_VALIDATE_POST(token) {
  return {
    url: API_URL + '/verifytoken',
    options: {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function ALL_USER_GET() {
  return {
    url: API_URL + '/client',
    options: {
      method: 'GET',
    },
  };
}

export function USER_GET(token, id) {
  return {
    url: `${API_URL}/user/id/${id}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function USER_POST(body) {
  return {
    url: API_URL + '/api/user',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function ATENDIMENTO_GET() {
  return {
    method: 'get',
    url: API_URL + '/atend/2023-01-01/2023-01-31',
  };
}

// export function PHOTO_POST(formData, token) {
//     return {
//         url: API_URL + '/api/photo',
//         options: {
//             method: 'POST',
//             headers: {
//                 Authorization: 'Bearer ' + token,
//             },
//             body: formData,
//         },
//     };
// }

// export function PHOTOS_GET({ page, total, user }) {
//     return {
//         url: `${API_URL}/api/photo/?_page=${page}&_total=${total}&_user=${user}`,
//         options: {
//             method: 'GET',
//             cache: 'no-store'
//         },
//     };
// }

// export function PHOTO_GET(id) {
//     return {
//         url: `${API_URL}/api/photo/${id}`,
//         options: {
//             method: 'GET',
//             cache: 'no-store'
//         },
//     };
// }

// export function COMMENT_POST(id, body) {
//     return {
//         url: `${API_URL}/api/comment/${id}`,
//         options: {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: 'Bearer ' + window.localStorage.getItem('token'),
//             },
//             body: JSON.stringify(body),
//         },
//     };
// }

// export function PHOTO_DELETE(id) {
//     return {
//         url: `${API_URL}/api/photo/${id}`,
//         options: {
//             method: 'DELETE',
//             headers: {
//                 Authorization: 'Bearer ' + window.localStorage.getItem('token'),
//             },
//         },
//     };
// }

// export function PASSWORD_LOST(body) {
//     return {
//       url: API_URL + '/api/password/lost',
//       options: {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(body),
//       },
//     };
//   }

// export function PASSWORD_RESET(body) {
//     return {
//         url: API_URL + '/api/password/reset',
//         options: {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         },
//     }
// }

export function STATS_GET() {
  return {
    url: API_URL + '/api/stats',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}
