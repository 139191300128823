import React from 'react'

import {  
    useColorModeValue,  
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Button,
    Text,
  } from '@chakra-ui/react';



export function ButtonPopver({title, children, colorB}) {
    return (
        <Popover>
        <PopoverTrigger>
          <Button colorScheme='black' variant='ghost'>
          <Text color={colorB} _hover={{ color: "yellow"}}>{title}</Text>
          </Button >
        </PopoverTrigger>
        <PopoverContent bg={useColorModeValue('black', 'gray.800')}>
          <PopoverBody>
            {children}
          </PopoverBody>
        </PopoverContent>
      </Popover>  
    );
}

export default ButtonPopver