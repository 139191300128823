import React from 'react'
import { Link as LinkNav } from "react-router-dom";
import { UserContext } from '../../Context/UserContext';
import {
    Box,
    chakra,
    Container,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
    Image, 
  } from '@chakra-ui/react';

  import { FaInstagram, FaInternetExplorer} from 'react-icons/fa';
  import logo from '../../Assets/Png/logoFooter.png'
  import logoBlack from '../../Assets/Png/logoBlackFooter.png'

  
  const SocialButton = ({
    children,
    label,
    href,
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        target="_blank" 
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
  
  export default function Footer() {
    const { login } = React.useContext(UserContext);
    const bgColor = useColorModeValue('gray.50', 'black')

    return (
      <>
        <Box bg={bgColor} position="fixed" bottom='0' w='100%' mt='4'>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={2}
          direction={{ base: 'row', md: 'row' }}
          spacing={4}
          justify={'space-between'}
          align={{ base: 'center', md: 'center' }}>


          <LinkNav to={'/'} >
          {bgColor=== 'black' ? 
           (<Image src={logo} h='6'/>)
          : 
          (<Image src={logoBlack} h='6'/>)
          }
          </LinkNav >

          <Stack direction={'row'} spacing={6}>
             {/* <SocialButton label={'Github'} href={'https://github.com/erleydiangelys'}>
              <FaGithub />
            </SocialButton> */}
            <SocialButton label={'Instagram'} href={'https://www.instagram.com/si.informatic/'}>
              <FaInstagram />
            </SocialButton>
            <SocialButton label={'Site'} href={'https://www.siprogramas.com.br/'}>
              <FaInternetExplorer />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
  </>
    );
  }