import React, { StrictMode } from 'react';
import { ColorModeScript } from '@chakra-ui/react';
import theme from './styles/theme'
import * as ReactDOM from 'react-dom/client';
import App from './App';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <>
    <ColorModeScript/>
    <App />
  </>
);
