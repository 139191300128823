import React, { Suspense }  from 'react';
import { BrowserRouter} from 'react-router-dom';
import { UserDataContext } from './Context/UserContext';


import { ChakraProvider} from '@chakra-ui/react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Loading from './Components/Loading';
import theme from './styles/theme';

const Router = React.lazy(() => import('./Router'))

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Suspense fallback={<Loading />}>
       <BrowserRouter>
       <UserDataContext>
        <Header />
        <Router />
        <Footer />
        </UserDataContext>
       </BrowserRouter>
      </Suspense>
    </ChakraProvider>
  );
}

export default App;
